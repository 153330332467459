.footer {
    display: flex;
    flex-direction: column;
    color: white;
    background-color: rgb(58, 131, 155);
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 100px; /* Aligned with .impacts-sections */
    box-sizing: border-box;
    align-items: center;
}

.contact-footer {
    flex: 3;
    
}

.logo-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.content-answer-footer {
    font-size: 10px;
    align-items: center;
    justify-content: center;
}

.link-footer, .Contact-values, .values {
    margin-top: 10px;
}

.useful-links-footer {
    margin-top: 20px;
}

.copyright, .space, .space-comming-soon {
    padding: 10px;
}

@media (min-width: 768px) {
    .footer {
        flex-direction: row;
        padding: 50px 5% 50px 100px;
    }

    .brand-footer, .content-answer-footer, .contact-footer {
        flex: 1;
    }

    .content-answer-footer {
        margin-left: 50px;
    }

    .brand-footer, .useful-links-footer {
        margin-top: 50px;
    }

    .link-footer, .Contact-values, .values {
        margin-top: 20px;
    }

    .copyright, .space, .space-comming-soon {
        padding: 50px;
    }
}

/* Copyright Styles */
.copyright-container {
    display: flex;
    flex-direction: column;
    padding: 5% 5% 5% 100px; /* Aligned with .impacts-sections */
    box-sizing: border-box;
    text-align: center;
    color: white;
    background-color: rgb(58, 131, 155);
}

.copyright-text, .powered-by {
    margin: 10px 0;
}

.link {
    color: inherit;
    text-decoration: none;
}

.link:hover {
    color: white;
    font-weight: bold;
}

@media (min-width: 768px) {
    .copyright-container {
        padding: 50px 5% 50px 100px; /* Adjusted padding for larger screens */
    }
}
